import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { navigate, routes } from '@redwoodjs/router'
import DappForm from 'src/components/Dapp/DappForm'

export const QUERY = gql`
  query FIND_DAPP_BY_MNEMONIC($mnemonic: String!) {
    dapp: dapp(mnemonic: $mnemonic) {
      id
      createdAt
      mnemonic
      name
      description
      abi
      network
      creatorId
      contract
    }
  }
`
const UPDATE_DAPP_MUTATION = gql`
  mutation UpdateDappMutation($id: String!, $input: UpdateDappInput!) {
    updateDapp(id: $id, input: $input) {
      id
      createdAt
      mnemonic
      name
      description
      abi
      network
      creatorId
      contract
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Success = ({ dapp }) => {
  const [updateDapp, { loading, error }] = useMutation(UPDATE_DAPP_MUTATION, {
    onCompleted: (data) => {
      navigate(routes.dapp({ mnemonic: data.updateDapp.mnemonic }))
      toast.success('Dapp updated.')
    },
  })

  const onSave = (input, id) => {
    updateDapp({ variables: { id, input } })
  }

  return (
    <div className="rw-segment">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">
          Edit Dapp {dapp.name}
        </h2>
      </header>
      <div className="rw-segment-main">
        <DappForm dapp={dapp} onSave={onSave} error={error} loading={loading} />
      </div>
    </div>
  )
}
