import { Contract } from '@ethersproject/contracts'
import FlexibleForm from 'src/components/FlexibleForm'
import BigNumberHelper from 'src/components/BigNumberHelper'
import DappyMethodCall from 'src/dappy/methodCall/DappyMethodCall'
import { toast } from '@redwoodjs/web/toast'

import {
  getMethodDisplayName,
  getArgumentsFromMethod,
  formatContractArgs,
} from 'src/utils/contracts'
import { truncate } from 'src/utils/helpers'
import { unlockBrowser } from 'src/utils/web3'

const Method = ({
  method,
  contractAddress,
  network,
  defaultValues,
  hideArguments,
}) => {
  const [response, setResponse] = React.useState()

  const isViewMethod = method.stateMutability === 'view'

  const parseResponse = (res) => {
    if (typeof res === 'object') {
      if (res._isBigNumber) return <BigNumberHelper bn={res} />
      return JSON.stringify(res)
    }
    return res
  }

  const onSubmit = async ({ input }) => {
    try {
      const inputList = Object.keys(input).map((key) => input[key])
      let args = formatContractArgs(inputList).slice()
      const { walletProvider } = await unlockBrowser({ debug: false })
      const contract = new Contract(
        contractAddress,
        [method],
        walletProvider.getSigner()
      )
      if (isViewMethod) {
        const response = await contract[method.name](...args)
        console.log(`Calling method ${method.name}(${args}). Response:`)
        console.log(response)
        setResponse(parseResponse(response))
        return { error: null }
      }
      if (method.stateMutability === 'payable')
        args[args.length - 1] = { value: args[args.length - 1] }
      const tx = await contract[method.name](...args)
      return { tx }
    } catch (e) {
      console.log(e)
      toast.error(
        `${truncate(e.message, 100)} ${truncate(
          e?.error?.message,
          100
        )} ${truncate(e.data?.message, 300)}`
      )
      return { error: e }
    }
  }

  return (
    <>
      <DappyMethodCall
        // TODO: use proper non-collision id
        id={method.name}
        network={network}
        onSubmitCustomFunction={onSubmit}
        checkoutComponent={(props) => (
          <FlexibleForm
            id={`${method.name}`}
            name={getMethodDisplayName(method)}
            hideArguments={hideArguments}
            inputs={getArgumentsFromMethod(method)}
            defaultValues={defaultValues}
            {...props}
          />
        )}
      />

      {isViewMethod && (
        <div className="mt-4 bg-gray-50 px-4 py-5 text-center font-semibold  text-m">
          Result:
          <br /> {response}
        </div>
      )}
    </>
  )
}

export default Method
