import EmbedDappCell from 'src/components/Embed/EmbedDappCell'
import EmbedFarmCell from 'src/components/Embed/EmbedFarmCell'
import EmbedLayout from 'src/layouts/EmbedLayout/EmbedLayout'

const EmbedPage = ({ mnemonic }) => {
  return (
    <EmbedLayout>
      <EmbedDappCell mnemonic={mnemonic} />
      <EmbedFarmCell mnemonic={mnemonic} />
    </EmbedLayout>
  )
}

export default EmbedPage
