import React from 'react'

import { Web3PendingFull } from '@pi0neerpat/farm'

const FARMING_IMAGE = `/farming.png`

const PendingDefault = ({
  onSubmit,
  estimatedDuration,
  depositValue,
  withdrawalValue,
  isFinished,
  isWithdrawing,
  isClaiming,
  FOREGROUND_COLOR,
  ICON_IMAGE_SRC,
  ...rest
}) => (
  <Web3PendingFull
    isWithdrawing={isWithdrawing}
    isClaiming={isClaiming}
    estimatedDuration={estimatedDuration}
    isFinished={isFinished}
    amount={isWithdrawing || isClaiming ? withdrawalValue : depositValue}
    awakenPosterSrc={ICON_IMAGE_SRC}
    color={FOREGROUND_COLOR}
    sleepingPosterSrc={FARMING_IMAGE}
    onSubmit={() => window.location.reload()}
    {...rest}
  />
)

export default PendingDefault
