import { _bnToWholeNumber, getEthPrice } from '@pi0neerpat/web3utils'
import { formatUnits } from '@ethersproject/units'

export const onWalletChoose = () => {
  // Triggered whenever the wallet is choosen, before unlocking
  // eg. Display a loading state elsewhere in the app
}

export const onWalletUnlock = ({ walletAddress }) => {
  // Triggered whenever the wallet is unlocked
  // eg. Save user's address and re-set the loading state
}

export const walletList = [
  {
    name: 'Metamask',
    iconName: 'metamask',
    iconColor: '#F58C25',
    id: 'metamask',
  },
  {
    name: 'Coinbase Wallet',
    iconName: 'coinbase-wallet',
    iconColor: '#2F5CE2',
    id: 'walletLink',
  },
  {
    name: 'Mobile Wallet',
    iconName: 'mobile-wallet',
    iconColor: '#3B99FC',
    id: 'walletConnect',
    subText: 'Trust, Argent, Rainbow, Others',
  },
]

export const fetchGasData = async ({ debug, user }) => {
  try {
    const ETH_GAS_STATION_URL = 'https://ethgasstation.info/api/ethgasAPI.json'
    const DEFAULT_GAS_TIER = 'average'
    const FAST_GAS_TIER = 'fastest'

    const res = await fetch(ETH_GAS_STATION_URL)
    const gasData = await res.json()
    if (res.status !== 200 || !gasData) {
      return {
        error: `Error connecting to ethgasstation, got status: ${res.status}`,
      }
    }
    gasData.averageWait = gasData.avgWait // rename poorly named variable
    const ethPrice = await getEthPrice({ provider: user.walletProvider })
    const gasPrice = formatUnits(Math.round(gasData[DEFAULT_GAS_TIER]), 'gwei')
    const pricePerWei = (gasPrice * ethPrice) / 10

    if (debug) {
      console.log(
        `====== Gas Statistics ======
  Initial: ${gasData[DEFAULT_GAS_TIER] / 10} gwei, wait: ${
          gasData[`${DEFAULT_GAS_TIER}Wait`]
        } minutes
  Speedy option: ${gasData[FAST_GAS_TIER] / 10} gwei, wait: ${
          gasData[`${FAST_GAS_TIER}Wait`]
        } minutes
  ETH price: $${ethPrice}
  Price per wei (gas price * eth price): $${pricePerWei}
  Example token transfer:  79,000 wei limit * Price per wei = $${(
    pricePerWei * 79000
  ).toFixed(2)}`
      )
    }

    const APPROVAL_GAS_COST = 45000
    const DEPOSIT_GAS_COST = 450000
    const WITHDRAWAL_GAS_COST = 1000000

    const approvalDepositFee = APPROVAL_GAS_COST * pricePerWei
    const depositFee = DEPOSIT_GAS_COST * pricePerWei
    const withdrawalFee = WITHDRAWAL_GAS_COST * pricePerWei

    return {
      approvalDepositFee,
      depositFee,
      withdrawalFee,
      ethPrice,
      estimatedDuration: gasData.averageWait,
    }
  } catch (e) {
    console.log(
      'Error fetching gas data from ethgasstation.info, using typical values.',
      e
    )
    return {
      approvalDepositFee: 5,
      depositFee: 5,
      withdrawalFee: 5,
      ethPrice: 0,
      estimatedDuration: 2,
    }
  }
}
