import { useMutation } from '@redwoodjs/web'

import { toast } from '@redwoodjs/web/toast'
import { navigate, routes } from '@redwoodjs/router'
import DappForm from 'src/components/Dapp/DappForm'
import { useAuth } from '@redwoodjs/auth'

import { QUERY } from 'src/components/Dapp/DappsCell'

const CREATE_DAPP_MUTATION = gql`
  mutation CreateDappMutation($input: CreateDappInput!) {
    createDapp(input: $input) {
      id
      mnemonic
    }
  }
`

const NewDapp = () => {
  const { currentUser } = useAuth()

  const [createDapp, { loading, error }] = useMutation(CREATE_DAPP_MUTATION, {
    onCompleted: (data) => {
      toast.success('Dapp created.')
      navigate(routes.dapp({ mnemonic: data.createDapp.mnemonic }))
    },
  })

  const onSave = (input) => {
    createDapp({
      variables: { input: { ...input, creatorId: currentUser.id } },
    })
  }

  return (
    <div className="rw-segment">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">New Dapp</h2>
      </header>
      <div className="rw-segment-main">
        <DappForm onSave={onSave} loading={loading} error={error} />
      </div>
    </div>
  )
}

export default NewDapp
