import React, { useEffect } from 'react'

import { WalletUnlockLoading } from '@pi0neerpat/feather'
import { getSimplePoolData } from '@pi0neerpat/web3utils'

// Couldn't get this working, since setContext causes a re-render
// Which triggers onSubmit a second time and skips a step
const LoadDefault = ({
  onSubmit,
  user,
  farmData,
  POOL_ADDRESS,
  NETWORK,
  ...rest
}) => {
  const [error, setError] = React.useState(false)
  const loadFarmData = async () => {
    const { error, ..._farmData } = await getSimplePoolData({
      poolAddress: POOL_ADDRESS,
      debug: true,
      // TODO: will user ever be available here?
      provider: user?.walletProvider,
      network: NETWORK,
      maticRpcUrl: process.env.MATIC_RPC,
      infuraKey: process.env.INFURA_ENDPOINT_KEY,
      xdaiRpcUrl: process.env.XDAI_RPC,
    })
    if (error) {
      setError(true)
      return console.log(error)
    }
    onSubmit({ farmData: _farmData })
  }

  useEffect(() => {
    if (farmData) return
    console.log(`loading farm data...`)
    loadFarmData()
  }, [])

  if (error) return <>Oops! Something went wrong while loading this farm</>
  return <WalletUnlockLoading />
}

export default LoadDefault
