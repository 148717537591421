/* eslint-disable react/jsx-wrap-multilines */

import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { formatUnits } from '@ethersproject/units'

import { InfoFarmImpactActive, InfoFarmImpactInactive } from '@pi0neerpat/farm'

const BASICALLY_ZERO_INTEREST_RATE = 0.00000001

const isProduction = process.env.NODE_ENV === `production`

const InitialDefault = ({
  onSubmit,
  userData,
  farmData,
  user,
  BACKGROUND_COLOR,
  FOREGROUND_COLOR,
  POOL_ADDRESS,
  ICON_IMAGE_SRC,
  STAKING_TOKEN_SYMBOL,
  REWARD_TOKEN_SYMBOL,
  NETWORK,
  ...rest
}) => {
  const { t } = useTranslation(`farm`)
  const [isGettingRewards, setIsGettingRewards] = useState(false)

  const interestRateRewardsText = farmData.interestRateRewards?.toFixed(0)
  const availableRewards = Number(formatUnits(farmData.availableRewards, 18))
  const lockedAmount = Number(formatUnits(farmData.lockedAmount, 18))

  const interestRate =
    farmData.interestRateRewards === 0
      ? BASICALLY_ZERO_INTEREST_RATE
      : farmData.interestRateRewards

  const onDepositClick = () => {
    onSubmit()
  }

  const onWithdrawClick = () => {
    onSubmit({
      isWithdrawing: true,
      withdrawalValue: userData?.lockedAmount,
    })
  }

  const onClaimClick = () => {
    onSubmit({
      isClaiming: true,
      withdrawalValue: userData?.impactAmountCurrent,
    })
  }

  if (userData?.lockedAmount > 0 || userData?.impactAmountCurrent > 0)
    return (
      <InfoFarmImpactActive
        impactText={
          <Trans i18nKey="farm:yourEarningsFrom">
            rCHILI earned from your staked{` `}
            {{ lockedAmount: userData?.lockedAmount?.toLocaleString() }}{' '}
            {STAKING_TOKEN_SYMBOL}
          </Trans>
        }
        subtitle={
          <Trans i18nKey="farm:annualReturnRate">
            {{ interestRateRewardsText }}% APR ({REWARD_TOKEN_SYMBOL})
          </Trans>
        }
        impactAmount={userData.impactAmountCurrent}
        lockedAmount={userData.lockedAmount}
        interestRate={interestRate}
        onWithdrawClick={onWithdrawClick}
        onDepositClick={onDepositClick}
        onClaimClick={onClaimClick}
        backgroundColor={BACKGROUND_COLOR}
        foregroundColor={FOREGROUND_COLOR}
        imageLeftSrc={{ src: ICON_IMAGE_SRC }}
        imageRightSrc={{ src: ICON_IMAGE_SRC }}
        imageLeftAlt={t(`A token icon`)}
        imageRightAlt={t(`A token icon`)}
        {...rest}
      />
    )
  return (
    <InfoFarmImpactInactive
      impactText={
        <Trans i18nKey="farm:totalEarningsFrom">
          {STAKING_TOKEN_SYMBOL} locked
        </Trans>
      }
      subtitle={
        <Trans i18nKey="farm:yearlyReturnRate">
          {{ interestRateRewardsText }}% Current APR ({REWARD_TOKEN_SYMBOL})
        </Trans>
      }
      impactAmount={lockedAmount}
      interestRate={BASICALLY_ZERO_INTEREST_RATE}
      onSubmit={onDepositClick}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      imageLeftSrc={{ src: ICON_IMAGE_SRC }}
      imageRightSrc={{ src: ICON_IMAGE_SRC }}
      imageLeftAlt={t(`A token icon`)}
      imageRightAlt={t(`A token icon`)}
      footerText={t(`Cancel anytime`)}
      buttonText={t(`Start farming`)}
      {...rest}
    />
  )
}

export default InitialDefault
