import { Link, routes } from '@redwoodjs/router'

const FarmsLayout = ({ children }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-5">
      <main className="rw-main">{children}</main>
    </div>
  )
}

export default FarmsLayout
