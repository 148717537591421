import { FatalErrorBoundary } from '@redwoodjs/web'
import { FetchConfigProvider, useFetchConfig } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'
import { AuthProvider } from '@redwoodjs/auth'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import EthereumAuthClient from '@oneclickdapp/ethereum-auth'
import { ApolloClient, InMemoryCache } from '@apollo/client'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import './index.css'

let ethereum

const ApolloInjector = ({ children }) => {
  const { uri, headers } = useFetchConfig()
  let ethereum
  try {
    const graphQLClient = new ApolloClient({
      cache: new InMemoryCache(),
      uri,
      headers,
    })
    // Default option using Apollo Client
    const makeRequest = (mutation, variables) =>
      graphQLClient.mutate({
        mutation,
        variables,
      })

    // Alternative option using graphql-hooks
    // You'll also need to modify graphQLClient
    // const makeRequest = (query, variables) =>
    //   graphQLClient.request({
    //     query,
    //     variables,
    //   })

    ethereum = new EthereumAuthClient({
      makeRequest,
      // Note: you must set NODE_ENV manually when using Netlify
      debug: process.NODE_ENV !== 'production',
    })
  } catch (e) {
    console.log(e)
  }
  return React.cloneElement(children, { client: ethereum })
}

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodApolloProvider>
      <Toaster
        position="bottom-right"
        toastOptions={{
          success: { duration: 8000 },
          error: { duration: 10000 },
        }}
      />
      <FetchConfigProvider>
        <ApolloInjector>
          <AuthProvider client={ethereum} type="ethereum">
            <Routes />
          </AuthProvider>
        </ApolloInjector>
      </FetchConfigProvider>
    </RedwoodApolloProvider>
  </FatalErrorBoundary>
)
export default App
