export const availableNetworks = [
  'mainnet',
  'xdai',
  'matic',
  'goerli',
  'ropsten',
  'rinkeby',
  'kovan',
  'mumbai',
  'bsc-mainnet',
  'bsc-testnet',
  'private',
]
