import Farm from 'src/components/Farm/Farm'

export const QUERY = gql`
  query FindFarmByMnemonic($mnemonic: String!) {
    farm: farm(mnemonic: $mnemonic) {
      id
      createdAt
      updatedAt
      mnemonic
      contract
      name
      description
      network
      creatorId
      backgroundColor
      foregroundColor
      stakingToken
      rewardToken
      rewardTokenName
      stakingTokenName
      stakingTokenSymbol
      rewardTokenSymbol
      iconImageSrc
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Farm not found</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ farm }) => {
  return <Farm farm={farm} />
}
