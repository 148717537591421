/* eslint-disable no-console */
/* eslint-disable i18next/no-literal-string */
import { formatUnits } from '@ethersproject/units'
import { getPoolUser } from '@pi0neerpat/web3utils'

import { fetchGasData } from '../helpers'

// Define your own logic to execute in Step "unlock" Substep "load"
export const loadUserData = async ({ user, debug, balances, userData }) => {
  try {
    const { ethPrice, estimatedDuration } = await fetchGasData({
      user,
      debug,
    })
    return {
      ethPrice,
      estimatedDuration,
    }
  } catch (e) {
    console.log(e)
  }
}

// Define your own logic to execute in Step "checkout" after "onSubmit"
export const customCheckoutFunction = async ({}) => {}
