import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { navigate, routes } from '@redwoodjs/router'

import FarmForm from 'src/components/Farm/FarmForm'

export const QUERY = gql`
  query FIND_FARM_BY_MNEMONIC($mnemonic: String!) {
    farm: farm(mnemonic: $mnemonic) {
      id
      createdAt
      updatedAt
      mnemonic
      contract
      name
      description
      network
      creatorId
      backgroundColor
      foregroundColor
      stakingToken
      stakingTokenName
      stakingTokenSymbol
      rewardToken
      rewardTokenName
      rewardTokenSymbol
      iconImageSrc
    }
  }
`
const UPDATE_FARM_MUTATION = gql`
  mutation UpdateFarmMutation($id: String!, $input: UpdateFarmInput!) {
    updateFarm(id: $id, input: $input) {
      id
      mnemonic
      contract
      name
      description
      network
      creatorId
      backgroundColor
      foregroundColor
      iconImageSrc
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ farm }) => {
  const [updateFarm, { loading, error }] = useMutation(UPDATE_FARM_MUTATION, {
    onCompleted: () => {
      toast.success('Farm updated')
      navigate(routes.farms())
    },
  })

  const onSave = (input, id) => {
    updateFarm({ variables: { id, input } })
  }

  return (
    <div className="rw-segment">
      <header className="rw-segment-header">
        <h2 className="rw-heading rw-heading-secondary">Edit Farm {farm.id}</h2>
      </header>
      <div className="rw-segment-main">
        <FarmForm farm={farm} onSave={onSave} error={error} loading={loading} />
      </div>
    </div>
  )
}
