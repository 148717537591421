import { Set, Router, Route, Private } from '@redwoodjs/router'
// Layout
import FarmsLayout from 'src/layouts/FarmsLayout'
import FarmLayout from 'src/layouts/FarmLayout'
import DefaultLayout from 'src/layouts/DefaultLayout'
import DappsLayout from 'src/layouts/DappsLayout'
import DappLayout from 'src/layouts/DappLayout'
import UsersLayout from 'src/layouts/UsersLayout'
// Dapp
import DappsPage from 'src/pages/Dapp/DappsPage/DappsPage'
import DappPage from 'src/pages/Dapp/DappPage/DappPage'
import NewDappPage from 'src/pages/Dapp/NewDappPage/NewDappPage'
import EditDappPage from 'src/pages/Dapp/EditDappPage/EditDappPage'
import EmbedPage from 'src/pages/Embed/EmbedPage/EmbedPage'
// User
import EditUserPage from 'src/pages/User/EditUserPage/EditUserPage'
import UserPage from 'src/pages/User/UserPage/UserPage'
// Farm
import FarmPage from 'src/pages/Farm/FarmPage/FarmPage'
import FarmsPage from 'src/pages/Farm/FarmsPage/FarmsPage'
import NewFarmPage from 'src/pages/Farm/NewFarmPage/NewFarmPage'
import EditFarmPage from 'src/pages/Farm/EditFarmPage/EditFarmPage'

const Routes = () => {
  return (
    <Router>
      <Set wrap={DefaultLayout}>
        {/* =========  Root  ============ */}
        <Route path="/" page={HomePage} name="home" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route notfound page={NotFoundPage} />

        {/* =========  Private  ============ */}
        <Private unauthenticated="login">
          <Set wrap={UsersLayout}>
            <Route path="/users/{id}/edit" page={EditUserPage} name="editUser" />
            <Route path="/users/{id}" page={UserPage} name="user" />
            {/*  <Route path="/users" page={UsersPage} name="users" />*/}
          </Set>
          <Set wrap={DappsLayout}>
            <Route path="/dapps" page={DappsPage} name="dapps" />
            <Route path="/new" page={NewDappPage} name="newDapp" whileLoadingAuth={() => 'Loading...'} />
            <Route path="/{mnemonic}/edit" page={EditDappPage} name="editDapp" />
          </Set>
          <Set wrap={FarmsLayout}>
            <Route path="/farms" page={FarmsPage} name="farms" />
            <Route path="/farms/new" page={NewFarmPage} name="newFarm" whileLoadingAuth={() => 'Loading...'} />
            <Route path="/farms/{mnemonic}/edit" page={EditFarmPage} name="editFarm" />
          </Set>
        </Private>

        {/* =========  Public  ============ */}
        <Set wrap={DappLayout}>
          <Route path="/{mnemonic}" page={DappPage} name="dapp" />
        </Set>
        <Set wrap={FarmLayout}>
          <Route path="/farms/{mnemonic}" page={FarmPage} name="farm" />
        </Set>
      </Set>

      {/* =========  Public Embed  ============ */}
      <Route path="/embed/{mnemonic}" name="embed" page={EmbedPage} />
    </Router>
  )
}

export default Routes
