import {
  Form,
  FormError,
  FieldError,
  Label,
  TextField,
  Submit,
  TextAreaField,
} from '@redwoodjs/forms'

import NetworkDropdown from 'src/components/forms/NetworkDropdown'
import AddressField from 'src/components/forms/AddressField'

const DappForm = (props) => {
  const onSubmit = (data) => {
    data.network = data.network.toLowerCase()
    props.onSave(data, props?.dapp?.id)
  }

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={onSubmit} error={props.error}>
        <FormError
          error={props.error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />

        <Label
          name="name"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Name
        </Label>
        <TextField
          name="name"
          placeholder="My awesome dapp"
          defaultValue={props.dapp?.name}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
        />
        <FieldError name="name" className="rw-field-error" />

        <Label
          name="description"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Description
        </Label>
        <TextField
          name="description"
          placeholder="It does amazing things"
          defaultValue={props.dapp?.description}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          // validation={{ required: true }}
        />
        <FieldError name="description" className="rw-field-error" />

        <Label
          name="abi"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Abi
        </Label>
        <TextAreaField
          name="abi"
          placeholder='[{"anonymous":false,"inputs":[{"indexed":true,"internalType":"address","name":"owner"}]}]'
          defaultValue={props.dapp?.abi}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{
            required: true,
            mode: 'onBlur',
            validate: (value) => {
              try {
                return typeof JSON.parse(value) === 'object'
              } catch (e) {
                return false
              }
            },
          }}
        />
        <FieldError name="abi" className="rw-field-error" />

        <Label
          name="contract"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Contract address
        </Label>
        <AddressField
          name="contract"
          defaultValue={props.dapp?.contract}
          validation={{ required: true }}
        />
        <Label
          name="network"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Network name
        </Label>
        <NetworkDropdown
          name="network"
          defaultValue={props.dapp?.network}
          validation={{ required: true }}
        />
        <FieldError name="network" className="rw-field-error" />

        <div className="rw-button-group">
          <Submit disabled={props.loading} className="rw-button rw-button-blue">
            Save
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default DappForm
