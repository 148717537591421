import { Link, routes } from '@redwoodjs/router'
const DappsLayout = (props) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <header className="rw-header">
        <h1 className="rw-heading rw-heading-primary">
          <Link to={routes.dapps()} className="rw-link">
            Dapps
          </Link>
        </h1>
        <Link to={routes.newDapp()} className="rw-button rw-button-grey">
          <div className="rw-button-icon">+</div> New Dapp
        </Link>
      </header>
      <main>{props.children}</main>
    </div>
  )
}

export default DappsLayout
