import Embed from 'src/components/Embed/Embed'

export const QUERY = gql`
  query FIND_DAPP_OR_FARM_BY_MNEMONIC($mnemonic: String!) {
    dapp: dapp(mnemonic: $mnemonic) {
      id
      createdAt
      mnemonic
      name
      description
      abi
      network
      creatorId
      updatedAt
      contract
    }
  }
`

export const Loading = () => <div></div>

export const Empty = () => <></>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ dapp, farm }) => {
  return <Embed dapp={dapp} farm={farm} />
}
