import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { navigate, routes } from '@redwoodjs/router'
import FarmForm from 'src/components/Farm/FarmForm'
import { useAuth } from '@redwoodjs/auth'
import { Link } from '@pi0neerpat/snowflake'

const CREATE_FARM_MUTATION = gql`
  mutation CreateFarmMutation($input: CreateFarmInput!) {
    createFarm(input: $input) {
      id
      mnemonic
    }
  }
`

const NewFarm = () => {
  const { currentUser } = useAuth()
  const [createFarm, { loading, error }] = useMutation(CREATE_FARM_MUTATION, {
    onCompleted: (data) => {
      toast.dismiss()
      toast.success('Farm created')
      navigate(routes.farm({ mnemonic: data.createFarm.mnemonic }))
    },
    onError: () => {
      toast.dismiss()
    },
  })

  const onSave = (input) => {
    toast.loading('Generating farm...')
    createFarm({
      variables: { input: { ...input, creatorId: currentUser.id } },
    })
  }

  return (
    <>
      <p>
        Create a new interface for any farm.{' '}
        <b>The farm must use the industry standard "UniPool" contracts.</b>{' '}
        <br />
        <br />
        Don't have a farm yet? Learn how to deploy one{' '}
        <Link
          onClick={() =>
            window.open(
              'https://remix.ethereum.org/#version=soljson-v0.5.6+commit.b259423e.js&optimize=false&runs=200&gist=011fceaefbef8ccadc20acabfa8499dc&call=fileManager//open//browser/gist-011fceaefbef8ccadc20acabfa8499dc/README.md'
            )
          }
        >
          here
        </Link>
        .
      </p>
      <div className="rw-segment mt-4">
        <header className="rw-segment-header">
          <h2 className="rw-heading rw-heading-secondary">New Farm</h2>
        </header>
        <div className="rw-segment-main">
          <FarmForm onSave={onSave} loading={loading} error={error} />
        </div>
      </div>
    </>
  )
}

export default NewFarm
