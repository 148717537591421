import React, { useState } from 'react'

import Dappy from '@pi0neerpat/dappy'
import {
  DappyLayoutMaster,
  DappyBorderContainer,
  DappyNavigationWithWallet,
} from '@pi0neerpat/feather'
import { ThemeProvider } from '@pi0neerpat/snowflake'
import { disconnectWallets, getSavedWallet } from '@pi0neerpat/web3utils'

import { useAuth } from '@redwoodjs/auth'

import '../i18n'
import DappyConfig from './config'

const DappyFarm = ({ config }) => {
  const { currentUser, logOut } = useAuth()

  const dappyConfig = new DappyConfig(config)

  // TODO: Periodically update this, so its correct after sign-in
  const { walletAddress } = getSavedWallet()
  const isLoggedIn = !!walletAddress
  const onLogOut = () => {
    logOut()
    disconnectWallets()
  }
  return (
    <ThemeProvider>
      <DappyBorderContainer color={config.BACKGROUND_COLOR}>
        <DappyLayoutMaster>
          <DappyNavigationWithWallet
            hideNav={true}
            isLoggedIn={isLoggedIn}
            onLogOut={onLogOut}
          />
          <Dappy
            options={dappyConfig.options}
            maticRpcUrl={process.env.MATIC_RPC}
            mumbaiRpcUrl={process.env.MUMBAI_RPC}
            infuraKey={process.env.INFURA_ENDPOINT_KEY}
            xdaiRpcUrl={process.env.XDAI_RPC}
            debug
          />
        </DappyLayoutMaster>
      </DappyBorderContainer>
    </ThemeProvider>
  )
}

export default DappyFarm
