import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
import { Link, routes, navigate } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'

import DappyFarm from 'src/dappy/farm/DappyFarm'

const DELETE_FARM_MUTATION = gql`
  mutation DeleteFarmMutation($id: String!) {
    deleteFarm(id: $id) {
      id
    }
  }
`

const timeTag = (datetime) => {
  return (
    <time dateTime={datetime} title={datetime}>
      {new Date(datetime).toUTCString()}
    </time>
  )
}

const Farm = ({ farm }) => {
  const { currentUser } = useAuth()

  const [deleteFarm] = useMutation(DELETE_FARM_MUTATION, {
    onCompleted: () => {
      toast.success('Farm deleted')
      navigate(routes.farms())
    },
  })

  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete farm ' + id + '?')) {
      deleteFarm({ variables: { id } })
    }
  }

  return (
    <>
      <DappyFarm
        config={{
          ID: farm.id,
          NETWORK: farm.network,
          BACKGROUND_COLOR: farm.backgroundColor,
          FOREGROUND_COLOR: farm.foregroundColor,
          POOL_ADDRESS: farm.contract,
          STAKING_TOKEN_ADDRESS: farm.stakingToken,
          STAKING_TOKEN_SYMBOL: farm.stakingTokenSymbol,
          REWARD_TOKEN_SYMBOL: farm.rewardTokenSymbol,
          ICON_IMAGE_SRC: farm.iconImageSrc,
        }}
      />
      <div className="mt-6 rw-segment">
        <header className="rw-segment-header">
          <h2 className="rw-heading rw-heading-secondary">Farm Detail</h2>
        </header>
        <table className="rw-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td>{farm.name}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{farm.description}</td>
            </tr>
            <tr>
              <th>Embed URL</th>
              <td>https://oneclickdapp.com/embed/{farm.mnemonic}</td>
            </tr>
            <tr>
              <th>Contract</th>
              <td>{farm.contract}</td>
            </tr>
            <tr>
              <th>Network</th>
              <td>{farm.network}</td>
            </tr>
            <tr>
              <th>Background color</th>
              <td>{farm.backgroundColor}</td>
            </tr>
            <tr>
              <th>Foreground color</th>
              <td>{farm.foregroundColor}</td>
            </tr>
            <tr>
              <th>Staking token</th>
              <td>{farm.stakingToken}</td>
            </tr>
            <tr>
              <th>Reward token</th>
              <td>{farm.rewardToken}</td>
            </tr>
            <tr>
              <th>Staking token name</th>
              <td>{farm.stakingTokenName}</td>
            </tr>
            <tr>
              <th>Staking token symbol</th>
              <td>{farm.stakingTokenSymbol}</td>
            </tr>
            <tr>
              <th>Reward token name</th>
              <td>{farm.rewardTokenName}</td>
            </tr>
            <tr>
              <th>Reward token symbol</th>
              <td>{farm.rewardTokenSymbol}</td>
            </tr>
            <tr>
              <th>Icon image src</th>
              <td>{farm.iconImageSrc}</td>
            </tr>
            <tr>
              <th>Created at</th>
              <td>{timeTag(farm.createdAt)}</td>
            </tr>
            <tr>
              <th>Updated at</th>
              <td>{timeTag(farm.updatedAt)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav className="rw-button-group">
        {/* currentUser?.id === farm.creatorId && (
          <>
            <Link
              to={routes.editFarm({ mnemonic: farm.mnemonic })}
              className="rw-button rw-button-blue"
            >
              Edit
            </Link>
            <a
              href="#"
              className="rw-button rw-button-red"
              onClick={() => onDeleteClick(farm.id)}
            >
              Delete
            </a>
          </>
        ) */}
      </nav>
    </>
  )
}

export default Farm
