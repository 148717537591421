import {
  WalletUnlockPrompt,
  WalletUnlockLoading,
  WalletUnlockBalance,
  WalletUnlockError,
  WalletUnlockNetwork,
  WalletUnlockInstall,
  Web3TxCheckoutRejected,
  Web3PendingFailed,
  DappyNavigation,
  // DappyNavigationWithWallet,
} from '@pi0neerpat/feather'

import {
  LoadDefault,
  InitialDefault,
  PendingDefault,
  CheckoutDefault,
} from './components'

import { loadUserData, customCheckoutFunction } from './functions'

import { walletList, onWalletChoose, onWalletUnlock } from '../helpers'
export default class DappyConfig {
  constructor(config) {
    this.ID = config.ID
    this.NETWORK = config.NETWORK
    this.BACKGROUND_COLOR = config.BACKGROUND_COLOR
    this.FOREGROUND_COLOR = config.FOREGROUND_COLOR
    this.POOL_ADDRESS = config.POOL_ADDRESS
    this.STAKING_TOKEN_ADDRESS = config.STAKING_TOKEN_ADDRESS
    this.STAKING_TOKEN_SYMBOL = config.STAKING_TOKEN_SYMBOL
    this.REWARD_TOKEN_SYMBOL = config.REWARD_TOKEN_SYMBOL

    this.options = {
      id: this.ID,
      stepOrder: [
        `load`,
        `silentUnlock`,
        `initial`,
        `unlock`,
        `checkout`,
        `pending`,
      ],
      // navigationComponent: DappyNavigationWithWallet,
      context: { ...config }, // Initial context
      stepDetails: {
        load: {
          category: `info`,
          substeps: { default: { component: LoadDefault } },
        },
        silentUnlock: {
          ...this.unlock(),
          hideNav: true,
          hide: true,
          // skippable: true, // Not sure why this is needed. W/o it skips the next step
        },
        initial: {
          category: `info`,
          hideNav: false,
          substeps: { default: { component: InitialDefault } },
        },
        unlock: this.unlock(),
        checkout: {
          category: `web3`,
          type: `tx`,
          onSubmitCustomFunction: customCheckoutFunction,
          // You must include the "approve" entity if you wish to do an approval
          approve: {
            // Alternatively, values are overridden using onApprove()
            // anything else passed to onApprove will be saved to context
            // amount: 100 // Defaults to unlimited
            waitForConfirmation: true,
            token: this.STAKING_TOKEN_ADDRESS,
            spender: this.POOL_ADDRESS,
          },
          substeps: {
            default: {
              component: CheckoutDefault,
              props: {
                foregroundColor: this.FOREGROUND_COLOR,
                impactText: this.IMPACT_TEXT,
              },
            },
            rejected: { component: Web3TxCheckoutRejected },
          },
        },
        pending: {
          category: `web3`,
          type: `pending`,
          // If true, will stay on default substep.
          pauseOnSuccess: true, // Defaults to false
          // onSuccess, (receipt) untested!
          substeps: {
            default: { component: PendingDefault },
            // Here we don't need to provide a substep for success
            // Since we are using pauseOnSuccess
            // success: { component: Web3PendingSuccess },
            failed: { component: Web3PendingFailed },
          },
        },
      },
    }
  }

  unlock = () => ({
    category: `wallet`,
    type: `unlock`,
    network: this.NETWORK, // defaults to homestead
    skippable: true, // Automatically load wallet. defaults to false
    allowConcurrentTransactions: false, // defaults to false
    onWalletChoose, // (walletType)
    onWalletUnlock, // (walletAddress, network)
    // navigationComponent: DappyNavigation,
    substeps: {
      default: {
        component: WalletUnlockPrompt, // WalletUnlockPrompt
        props: { walletList },
      },
      load: {
        balanceList: [this.STAKING_TOKEN_ADDRESS],
        allowanceList: [this.STAKING_TOKEN_ADDRESS],
        spenderAddress: this.POOL_ADDRESS,
        component: WalletUnlockLoading,
        customAsyncFunction: loadUserData, // Returned values are saved to context
      },
      error: { component: WalletUnlockError },
      network: {
        component: WalletUnlockNetwork,
        props: { networkName: this.NETWORK },
      },
      install: { component: WalletUnlockInstall },
    },
  })
}
