import {
  Form,
  FormError,
  FieldError,
  Label,
  TextField,
  Submit,
} from '@redwoodjs/forms'
import NetworkDropdown from 'src/components/forms/NetworkDropdown'
import AddressField from 'src/components/forms/AddressField'

const formatDatetime = (value) => {
  if (value) {
    return value.replace(/:\d{2}\.\d{3}\w/, '')
  }
}

const FarmForm = (props) => {
  const onSubmit = (data) => {
    props.onSave(data, props?.farm?.id)
  }

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={onSubmit} error={props.error}>
        <Label
          name="name"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Name
        </Label>
        <TextField
          name="name"
          defaultValue={props.farm?.name}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
        />

        <FieldError name="name" className="rw-field-error" />

        <Label
          name="description"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Description
        </Label>
        <TextField
          name="description"
          defaultValue={props.farm?.description}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
        />

        <FieldError name="description" className="rw-field-error" />

        <Label
          name="network"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Network - (BSC not currently supported)
        </Label>
        <NetworkDropdown
          name="network"
          defaultValue={props.farm?.network}
          validation={{ required: true }}
        />

        <FieldError name="network" className="rw-field-error" />

        <Label
          name="contract"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Contract
        </Label>
        <AddressField
          name="contract"
          defaultValue={props.farm?.contract}
          validation={{ required: true }}
        />

        <FieldError name="contract" className="rw-field-error" />

        <Label
          name="backgroundColor"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Background color
        </Label>
        <TextField
          name="backgroundColor"
          defaultValue={props.farm?.backgroundColor || '#FFFFFF'}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{
            required: true,
            pattern: { value: /#[0-9a-zA-Z]{6}/ },
          }}
        />

        <FieldError name="backgroundColor" className="rw-field-error" />

        <Label
          name="foregroundColor"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Foreground color
        </Label>
        <TextField
          name="foregroundColor"
          defaultValue={props.farm?.foregroundColor || '#000000'}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{
            required: true,
            pattern: { value: /#[0-9a-zA-Z]{6}/ },
          }}
        />

        <FieldError name="foregroundColor" className="rw-field-error" />

        <Label
          name="iconImageSrc"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Icon image src (Link to image)
        </Label>
        <TextField
          name="iconImageSrc"
          defaultValue={props.farm?.iconImageSrc}
          placeholder="https://mysite.com/logo.png"
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{
            required: true,
            pattern: {
              value:
                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
            },
          }}
        />
        <FieldError name="iconImageSrc" className="rw-field-error" />

        <Label
          name="stakingToken"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Staking token
        </Label>
        <TextField
          name="stakingToken"
          disabled
          placeholder="(Fetched from contract)"
          defaultValue={props.farm?.stakingToken}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />

        <FieldError name="stakingToken" className="rw-field-error" />

        <Label
          name="rewardToken"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Reward token
        </Label>
        <TextField
          name="rewardToken"
          disabled
          placeholder="(Fetched from contract)"
          defaultValue={props.farm?.rewardToken}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />

        <FieldError name="rewardToken" className="rw-field-error" />

        <Label
          name="rewardTokenName"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Reward token name
        </Label>
        <TextField
          name="rewardTokenName"
          disabled
          placeholder="(Fetched from contract)"
          defaultValue={props.farm?.rewardTokenName}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />

        <FieldError name="rewardTokenName" className="rw-field-error" />

        <Label
          name="stakingTokenName"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Staking token name
        </Label>
        <TextField
          name="stakingTokenName"
          disabled
          placeholder="(Fetched from contract)"
          defaultValue={props.farm?.stakingTokenName}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
        />

        <FieldError name="stakingTokenName" className="rw-field-error" />

        <FormError
          error={props.error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        <div className="rw-button-group">
          <Submit disabled={props.loading} className="rw-button rw-button-blue">
            Save
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default FarmForm
