import { Link, routes } from '@redwoodjs/router'

import Farms from 'src/components/Farm/Farms'

export const QUERY = gql`
  query FindFarms {
    farms {
      id
      createdAt
      updatedAt
      mnemonic
      contract
      name
      description
      network
      creatorId
      backgroundColor
      foregroundColor
      stakingToken
      rewardToken
      rewardTokenName
      stakingTokenName
      iconImageSrc
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => {
  return (
    <div className="rw-text-center">
      {'No farms yet. '}
      <Link to={routes.newFarm()} className="rw-link">
        {'Create one?'}
      </Link>
    </div>
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ farms }) => {
  return <Farms farms={farms} />
}
