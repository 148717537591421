import { Link, routes } from '@redwoodjs/router'

const UsersLayout = (props) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <header className="rw-header">
        <h1 className="rw-heading rw-heading-primary">Users</h1>
      </header>
      <main className="max-w-7xl mx-auto px-4 sm:px-6">{props.children}</main>
    </div>
  )
}

export default UsersLayout
