import React, { useState } from 'react'
import Dappy from '@pi0neerpat/dappy'
import { DappyLayoutMaster, DappyBorderContainer } from '@pi0neerpat/feather'
import { ThemeProvider } from '@pi0neerpat/snowflake'

import '../i18n'
import DappyConfig from './config'

import { BACKGROUND_COLOR, FOREGROUND_COLOR } from './constants'

const DappyMethodCall = (config) => {
  // const [dappyBorder, setDappyBorder] = useState(BACKGROUND_COLOR)
  // const [isDappyActive, setIsDappyActive] = useState(false)
  const isDappyActive = false
  const dappyBorder = BACKGROUND_COLOR

  const dappyConfig = new DappyConfig({
    ...config,
    BACKGROUND_COLOR,
    FOREGROUND_COLOR,
  })
  return (
    <ThemeProvider>
      <DappyBorderContainer color={dappyBorder} isDappyActive={isDappyActive}>
        <DappyLayoutMaster>
          <Dappy
            options={dappyConfig.options}
            // onToggleActive={onToggleActive}
            infuraKey={process.env.INFURA_ENDPOINT_KEY}
            maticRpcUrl={process.env.MATIC_RPC}
            mumbaiRpcUrl={process.env.MUMBAI_RPC}
            xdaiRpcUrl={process.env.XDAI_RPC}
            debug
          />
        </DappyLayoutMaster>
      </DappyBorderContainer>
    </ThemeProvider>
  )
}

export default DappyMethodCall
