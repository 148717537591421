import {
  WalletUnlockLoading,
  DappyLayoutMaster,
  DappyBorderContainer,
} from '@pi0neerpat/feather'
const EmbedLayout = (props) => {
  return (
    <div className="relative w-screen h-screen">
      <div className="absolute inset-1/2">
        <WalletUnlockLoading />
      </div>
      <div className="absolute w-full top-0">
        <main>{props.children}</main>
      </div>
    </div>
  )
}

export default EmbedLayout
