export const BACKGROUND_COLOR = '#FFEAEC'
export const FOREGROUND_COLOR = '#393E41'

export const merchantList = [
  {
    url:
      'https://app.uniswap.org/#/add/0x90DE74265a416e1393A450752175AED98fe11517/ETH',
    name: 'Uniswap',
    id: 'uniswap',
    iconName: 'uniswap',
    iconColor: '#FF00FF',
    bgColor: '#FFE6FF',
  },
]
