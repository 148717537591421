import { getWriteMethods, getViewMethods } from 'src/utils/contracts'
import { useParams } from '@redwoodjs/router'

import Method from 'src/components/Method'
import DappyFarm from 'src/dappy/farm/DappyFarm'

// import { QUERY } from 'src/components/Dapp/DappsCell'

const Embed = ({ dapp, farm }) => {
  const {
    method: methodName,
    arguments: defaultValues,
    hideArguments,
  } = useParams()

  if (dapp) {
    const abi = JSON.parse(dapp.abi)
    const writeMethods = getWriteMethods(abi)

    let method = writeMethods[0]
    const specifiedMethod = writeMethods.find(
      (method) => method.name === methodName
    )
    if (specifiedMethod) method = specifiedMethod
    return (
      <Method
        hideArguments={hideArguments}
        method={method}
        defaultValues={defaultValues && defaultValues.split(',')}
        network={dapp.network}
        contractAddress={dapp.contract}
      />
    )
  }
  return (
    <DappyFarm
      config={{
        ID: farm.id,
        NETWORK: farm.network,
        BACKGROUND_COLOR: farm.backgroundColor,
        FOREGROUND_COLOR: farm.foregroundColor,
        POOL_ADDRESS: farm.contract,
        STAKING_TOKEN_ADDRESS: farm.stakingToken,
        STAKING_TOKEN_SYMBOL: farm.stakingTokenSymbol,
        REWARD_TOKEN_SYMBOL: farm.rewardTokenSymbol,
        ICON_IMAGE_SRC: farm.iconImageSrc,
      }}
    />
  )
}

export default Embed
