import {
  WalletUnlockPrompt,
  WalletUnlockLoading,
  WalletUnlockBalance,
  WalletUnlockError,
  WalletUnlockNetwork,
  WalletUnlockInstall,
  Web3TxCheckoutRejected,
  Web3PendingFailed,
  DappyNavigation,
} from '@pi0neerpat/feather'

import { LoadDefault, PendingDefault, CheckoutDefault } from './components'

import { loadUserData, customCheckoutFunction } from './functions'

import { walletList } from '../helpers'

const PendingSuccess = () => <div className="">Success!</div>
const PendingFailed = () => <div className="">Transaction Failed</div>

export default class DappyConfig {
  constructor(config) {
    this.ID = config.id
    this.NETWORK = config.network
    this.BACKGROUND_COLOR = config.BACKGROUND_COLOR
    this.FOREGROUND_COLOR = config.FOREGROUND_COLOR
    this.CHECKOUT_COMPONENT = config.checkoutComponent
    this.ON_SUBMIT_CUSTOM_FUNCTION = config.onSubmitCustomFunction

    this.options = {
      id: this.ID,
      stepOrder: [`silentUnlock`, `unlock`, `checkout`, `pending`],
      navigationComponentName: `DappyNavigation`,
      context: { ...config }, // Initial context
      stepDetails: {
        silentUnlock: {
          ...this.unlock(),
          hideNav: true,
          hide: true,
          // skippable: true, // Not sure why this is needed. W/o it skips the next step
        },
        unlock: this.unlock(),
        checkout: {
          category: `web3`,
          type: `tx`,
          onSubmitCustomFunction: this.ON_SUBMIT_CUSTOM_FUNCTION,
          // You must include the "approve" entity if you wish to do an approval
          // approve: {
          // Alternatively, values are overridden using onApprove()
          // anything else passed to onApprove will be saved to context
          // amount: 100 // Defaults to unlimited
          // waitForConfirmation: true,
          // token: this.STAKING_TOKEN_ADDRESS,
          // spender: this.POOL_ADDRESS,
          // },
          substeps: {
            default: {
              component: this.CHECKOUT_COMPONENT,
            },
            rejected: { component: Web3TxCheckoutRejected },
          },
        },
        pending: {
          category: `web3`,
          type: `pending`,
          // If true, will stay on default substep.
          pauseOnSuccess: true, // Defaults to false
          // onSuccess, (receipt) untested!
          substeps: {
            default: { component: PendingDefault },
            // Here we don't need to provide a substep for success
            // Since we are using pauseOnSuccess
            // success: { component: Web3PendingSuccess },
            failed: { component: Web3PendingFailed },
          },
        },
      },
    }
  }

  unlock = () => ({
    category: `wallet`,
    type: `unlock`,
    network: this.NETWORK, // defaults to homestead
    skippable: true, // Automatically load wallet. defaults to false
    allowConcurrentTransactions: false, // defaults to false
    // onWalletChoose, // (walletType)
    // onWalletUnlock, // (walletAddress, network)
    navigationComponent: DappyNavigation,
    substeps: {
      default: {
        component: WalletUnlockPrompt, // WalletUnlockPrompt
        props: { walletList },
      },
      load: {
        component: WalletUnlockLoading,
        customAsyncFunction: loadUserData, // Returned values are saved to context
      },
      error: { component: WalletUnlockError },
      network: {
        component: WalletUnlockNetwork,
        props: { networkName: this.NETWORK },
      },
      install: { component: WalletUnlockInstall },
    },
  })
}
